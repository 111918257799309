<template>
    <uw-content title="库存调拨">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.moveCreate.Open()" >新建调拨</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <ims-warehouse-select  v-model="search.warehoser_id" @onChange="TableSearch()" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 状态 -->
                    <vxe-column width="80" title="状态">
                        <template #default="{ row }">
                            <el-tag effect="dark" size="mini" v-if="row.state">已调拨</el-tag>
                            <el-tag effect="dark" size="mini" v-if="!row.state" type="danger">已作废</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 调拨编号 -->
                    <vxe-column width="140" title="调拨编号">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.moveDrawer.Open(row.id)">{{ row.number }}</span>
                        </template>
                    </vxe-column>

                    <!-- 出库单号 -->
                    <vxe-column min-width="120" field="name" title="调拨名称"></vxe-column>
                    <vxe-column min-width="100" field="date" title="调拨时间"></vxe-column>
                    <vxe-column min-width="100" field="back_warehouse.name" title="调出仓库"></vxe-column>
                    <vxe-column min-width="100" field="enter_warehouse.name" title="调入仓库"></vxe-column>
                    <vxe-column min-width="120" field="describe" title="备注"></vxe-column>
                    <vxe-column width="80" field="created_user.name" title="负责人"></vxe-column>
                    <vxe-column width="140" field="created_at" title="创建时间"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-warehouse-move-create ref="moveCreate" @onChange="TableSearch" />
        <ims-warehouse-move-drawer ref="moveDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                warehoser_id: null,
                number: null,
                action: 0
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 数据查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/warehouse-move/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user','back_warehouse','enter_warehouse']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }

}
</script>